<template>
  <main
    class="mt-10 md:mt-1 flex flex-col-reverse gap-8 items-center md:flex-row md:gap-16 md:justify-center min-h-[50vh] md:min-h-[50vh]">
    <div class="space-y-2 text-center md:text-left px-10">
      <p class="text-[#92FE9D] fadein-up">Hello World, I'm</p>
      <h1 class="text-4xl font-bold md:text-5xl text-white fadein-up">Aggra Abhy</h1>
      <div class="py-2">
        <h1
          class="typewrite text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#92FE9D] to-[#00C9FF] md:text-2xl fadein-up"
          ref="typewriter">
          <span class="wrap">{{ txt }}</span>
        </h1>
      </div>
      <p class="text-white pr-4 fade-in-from-left">Welcome to My personal website. <span class="wave"><svg version="1.1"
            id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
            height="20px" viewBox="0 0 226.777 226.777" enable-background="new 0 0 226.777 226.777"
            xml:space="preserve">
            <g>
              <polygon fill="#231F20" points="112.553,157 112.553,86.977 44.158,116.937 	" />
              <polygon fill="#231F20" points="112.553,82.163 112.553,-0.056 46.362,111.156 	" />
              <polygon fill="#231F20" points="116.962,-0.09 116.962,82.163 184.083,111.566 	" />
              <polygon fill="#231F20" points="116.962,86.977 116.962,157.002 185.405,116.957 	" />
              <polygon fill="#231F20" points="112.553,227.406 112.553,171.085 44.618,131.31 	" />
              <polygon fill="#231F20" points="116.962,227.406 184.897,131.31 116.962,171.085 	" />
            </g>
          </svg></span></p>
      <br>
      <!-- <button
        class="fadein-bot fade-500 flex items-center py-2 px-4 mx-auto text-sm font-medium rounded-lg border transition duration-300 md:py-2.5 md:px-5 md:mx-0 text-amber-200 border-amber-200 hover:bg-amber-200 hover:bg-opacity-10 bg-transparent focus:outline-none w-fit"><svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-2 w-4 h-4">
          <path fill-rule="evenodd"
            d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
            clip-rule="evenodd"></path>
          <path
            d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z">
          </path>
        </svg>Download Resume</button> -->
    </div>
    <div class="flex justify-center md:justify-start fadein-right pict">
      <div class="gradient-border rounded-full p-0.5">
        <img alt="avatar" fetchpriority="high" width="300" height="300" decoding="async"
          class="w-8/10 md:h-auto rounded-full" src="/img/foto_2.jpg">
      </div>
    </div>

  </main>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      toRotate: ["Law Student", "Crypto Enthusiast", "Onchain Analyst"],
      period: 2000,
      txt: '',
      loopNum: 0,
      isDeleting: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tick();
    });
  },
  methods: {
    tick() {
      let typewriter = this.$refs.typewriter;

      if (!typewriter) {
        return;
      }

      let i = this.loopNum % this.toRotate.length;
      let fullTxt = this.toRotate[i];

      this.txt = this.isDeleting ? fullTxt.substring(0, this.txt.length - 1) : fullTxt.substring(0, this.txt.length + 1);
      typewriter.innerHTML = `<span class="wrap">${this.txt}</span>`;

      let that = this;
      let delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(() => {
        that.tick();
      }, delta);
    },
  }
}
</script>

<style>
body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.typewrite>.wrap {
  border-right: 0.08em solid #fff;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg)
  }

  10% {
    transform: rotate(14deg)
  }

  20% {
    transform: rotate(-8deg)
  }

  30% {
    transform: rotate(14deg)
  }

  40% {
    transform: rotate(-4deg)
  }

  50% {
    transform: rotate(10deg)
  }

  60% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(0deg)
  }
}

/* .pict {
  box-shadow: 0px 0px 73px -9px rgba(255,219,112,0.44);
-webkit-box-shadow: 0px 0px 73px -9px rgba(255,219,112,0.44);
-moz-box-shadow: 0px 0px 73px -9px rgba(255,219,112,0.44);
} */

/* NOTE JANGAN DIHAPUS : background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%); */

/* .pict {
  box-shadow: 0px 0px 73px -9px rgba(0, 201, 255, 0.44),
    0px 0px 73px -9px rgba(146, 254, 157, 0.44);
  -webkit-box-shadow: 0px 0px 73px -9px rgba(0, 201, 255, 0.44),
    0px 0px 73px -9px rgba(146, 254, 157, 0.44);
  -moz-box-shadow: 0px 0px 73px -9px rgba(0, 201, 255, 0.44),
    0px 0px 73px -9px rgba(146, 254, 157, 0.44);
} */

.pict {
  box-shadow: 0px 0px 25px 7px rgba(0, 201, 255, 0.4),
    0px 0px 25px 7px rgba(146, 254, 157, 0.4);
  -webkit-box-shadow: 0px 0px 25px 7px rgba(0, 201, 255, 0.4),
    0px 0px 25px 7px rgba(146, 254, 157, 0.4);
  -moz-box-shadow: 0px 0px 25px 7px rgba(0, 201, 255, 0.4),
    0px 0px 25px 7px rgba(146, 254, 157, 0.4);
  background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
  /* Apply gradient background */
  padding: 1px;
  /* Padding to space out the image within the gradient */
  border-radius: 50%;
  /* Ensure the entire div is rounded */
}

.gradient-border {
  background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
  padding: 5px;
  /* Control thickness of gradient border */
  border-radius: 50%;
}

.gradient-border img {
  border: 4px solid transparent;
  border-radius: 50%;
  /* Ensures image stays rounded */
}


.gradient-border {
  background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
  padding: 5px;
  /* Adjust the padding to control the thickness of the gradient border */
  border-radius: 50%;
  /* Ensures the gradient border is rounded */
}

.gradient-border img {
  border: 4px solid transparent;
  /* Transparent border to show gradient padding */
  display: block;
  border-radius: 50%;
  /* Ensures the image stays rounded */
}


.fadein-up {
  opacity: 0;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-from-left {
  opacity: 0;
  animation: fadeInLeft 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-right {
  opacity: 0;
  animation: fadeInRight 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-bot {
  opacity: 0;
  animation: fadeInBot 0.5s forwards;
}

@keyframes fadeInBot {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadein-1 {
  animation-delay: 200ms;
}

.fadein-2 {
  animation-delay: 400ms;
}

.fadein-3 {
  animation-delay: 600ms;
}

.fade-500 {
  animation-delay: 500ms;
}
</style>
